// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-recognize-activities-with-computer-vision-js": () => import("./../../../src/pages/projects/recognize-activities-with-computer-vision.js" /* webpackChunkName: "component---src-pages-projects-recognize-activities-with-computer-vision-js" */),
  "component---src-pages-technologies-index-js": () => import("./../../../src/pages/technologies/index.js" /* webpackChunkName: "component---src-pages-technologies-index-js" */),
  "component---src-pages-technologies-smart-home-js": () => import("./../../../src/pages/technologies/smart-home.js" /* webpackChunkName: "component---src-pages-technologies-smart-home-js" */),
  "component---src-pages-technologies-virtual-reality-js": () => import("./../../../src/pages/technologies/virtual-reality.js" /* webpackChunkName: "component---src-pages-technologies-virtual-reality-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

